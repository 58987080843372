import React, { useRef, useState } from 'react';
import Slider from "react-slick";
import { Container } from 'reactstrap';
import NavHeader from 'src/components/Common/NavHeader';
import SelectTemplate from './components/SelectTemplate';
import NotificationCard from './components/NotificationCard';
import Filtering from './components/Filtering';
import SelectCustomer from './components/SelectCustomer';
import useNotificationTemplates from 'src/hooks/useNotiffcationTemplates';
import { useHistory } from 'react-router';



const AddNotification = () => {
    const history = useHistory();
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        swipe: false
    }

    const sliderRef = React.createRef<typeof Slider>();
    const [template, setTemplate] = useState<any>(null);
    const [filter, setFilter] = useState<any>(null);
    const [index, setIndex] = useState<number>(0);
    const [customers, setCustomers] = useState<any>([]);
    const { sendTemplate } = useNotificationTemplates();
    const [loading, setLoading] = useState<boolean>(false);
    const customersRef = useRef<any>(null);

    const onNext = () => {
        if (index == 0 && !template) return;
        if (index == 1) {
            if (!filter) return;
            customersRef.current?.fetch();
        }
        if (index === 2) {
            onSend();
            return;
        }

        setIndex(index + 1);
        sliderRef.current.slickNext();
    }

    const onPrev = () => {
        if (index == 0) return;
        setIndex(index - 1);
        sliderRef.current.slickPrev();
    }

    const onChangeTemplate = (template: any) => {
        const updatedTemplate = {
            ...template,
            regNo: "Limited Time"
        };
        setTemplate(updatedTemplate);
    };

    const onChangeFilter = (filter: any) => {
        setFilter(filter);
    }

    
    const onSelectCustomers = (customers: any) => {
        const updatedCustomers = customers.map((customer: any) => ({
            ...customer,
            regNo: customer.regNo !== undefined ? customer.regNo : undefined
        }));
        setCustomers(updatedCustomers);
    }


    const onSend = async () => {
        setLoading(true)
        try {
            const data = {template, customers};
            await sendTemplate(data);
            setLoading(false)
            history.goBack();
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    return (
        <>
            <div className="page-content" style={{ height: '100vh', overflow: 'scroll' }}>
                <Container fluid>
                    <NavHeader
                        title='Add Notification'
                        description='You can send a notification to a specified user.'
                    />
                    <Slider ref={sliderRef} {...settings}>
                        <div >
                            <SelectTemplate
                                onChange={onChangeTemplate}
                            />
                        </div>
                        <div >
                            <NotificationCard
                                template={template}
                            />
                        </div>
                        <div >
                            <Filtering
                                onChange={onChangeFilter}
                                focused={index == 1}
                            />
                        </div>
                        <div >
                            <SelectCustomer
                                ref={customersRef}
                                filter={filter}
                                onSelect={onSelectCustomers}
                            />
                        </div>
                    </Slider>
                    <div className='d-flex justify-content-between mt-3'>
                        <button
                            type="button"
                            className="btn btn-primary w-md"
                            onClick={onPrev}
                        >
                            {index == 0 ? "Cancel" :
                                index == 2 ? "Edit" : "Back"}
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary w-md"
                            onClick={onNext}
                            disabled={index == 2 && customers.length == 0}
                        >
                            {index == 2 ? "Send" : "Next"}
                            {loading && <div className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></div>}
                        </button>
                    </div>
                </Container>
            </div>
        </>
    )


}

export default AddNotification;